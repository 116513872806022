<template>
  <div
    :class="'credit-page ' + LANGCODE"
    :style="'--aog-currency:\'' + SYSLANG.currency + '\';'"
  >
    <div class="header">
      <img class="member-bg" :src="require('@/assets/images/bg-index.png')" />
      <div class="credit-info">
        <div class="credit">
          {{ PAGELANG.可提现收益 }}
          <span class="num price">{{ agentInfo.availableMoney/100}}</span>
        </div>
        <div class="other">
          <div class="item">
            {{ PAGELANG.累计收益 }}
            <span class="num price">{{ agentInfo.totalProfitMoney/100 }}</span>
          </div>
          <div class="item">
            {{ PAGELANG.冻结收益 }}
            <span class="num price">{{ agentInfo.freezeMoney/100 }}</span>
          </div>
        </div>
        <a class="btn-withdraw" @click="goWithdraw">{{ PAGELANG.提现 }}</a>
      </div>
    </div>

    <ul class="credit-menu">
      <li>
        <a href="#/income/account">
          <span class="title">
            <i class="iconfont icon-qianbao-03" style="color: #f5a624" />
            {{ PAGELANG.提现账户 }}
          </span>
          <span>{{ agentInfo.AccountCount }}</span>
        </a>
      </li>
      <li>
        <a href="#/income/withdrawlogs">
          <span class="title">
            <i class="iconfont icon-qianbao-02" style="color: #f5a624" />
            {{ PAGELANG.提现明细 }}
          </span>
        </a>
      </li>
      <li>
        <a href="#/income/toberecorded">
          <span class="title">
            <i class="iconfont icon-qianbao-01" style="color: #3cadff" />
            {{ PAGELANG.待结算 }}
          </span>
          <span class="price">{{ agentInfo.freezeMoney/100 }}</span>
        </a>
      </li>
      <li>
        <a href="#/income/logs">
          <span class="title">
            <i class="iconfont icon-qianbao-04" style="color: #3cadff" />
            {{ PAGELANG.收益明细 }}
          </span>
        </a>
      </li>
    </ul>
  </div>

</template>

<script>
import {
  showConfirmDialog,
  showLoadingToast,
  closeToast,
} from "vant";
import { getCookie } from "../util";


export default {

  data() {
    return {
      agentInfo: [],
    };
  },
  beforeCreate() {
    if (!getCookie("agentlogintoken")) {
      this.$router.replace({
        path: "/login",
      });
      return;
    }
  },
  created() {
    if (!getCookie("agentlogintoken")) {
      return;
    }

    this.PAGELANG = this.LANG.pages.income;
    this.SYSLANG = this.LANG.system;
    this.LANGCODE = this.langcode;

    this.getMemberInfo();
  },
  methods: {
    getMemberInfo() {
      showLoadingToast({
        message: this.SYSLANG.loading,
        duration: 0,
        forbidClick: true,
      });
	  this.axios.get(this.actions.memberStatMine).then((response)=>{
		    closeToast();
		   let { code,data } = response.data;
           if(code == "200"){this.agentInfo = data;}		   
	  })		
    },
    goWithdraw() {
		this.axios.get(this.actions.accountsList,{params:{type:2}}).then((res) => {
		         
				 let code = res.data.code;
				 if(code==200){
					if(res.data.data[0].account==""){
						showConfirmDialog({
						  title: this.SYSLANG.dialogtitle,
						  message: this.PAGELANG.您还没有可用的提现账户是否马上添加,
						  confirmButtonText: this.PAGELANG.去添加,
						  cancelButtonText: this.SYSLANG.quxiao,
						 })
						  .then(() => {
						    this.$router.push({
						      path: "/income/account",
						    });
						  })
						//this.isAdd=true
						}else{
							this.$router.push({
							  path: "/income/withdraw",
							});
						}
				 }
		});
    },
  },
};
</script>


<style src="../assets/css/credit.css" scoped></style>
<style scoped>
.credit-page .header .credit-info .credit {
  margin-bottom: 0;
}

.credit-page .btn-withdraw {
  display: inline-block;
  line-height: 32px;
  font-size: 14px;
  padding: 0 20px;
  border: 1px solid #fff;
  border-radius: 20px;
  color: #fff;
}
</style>